import React, { createContext, useState, useContext, ReactNode } from 'react';

type SpinProviderProps = {
    children: ReactNode;
};

const SpinContext = createContext<any>(null);

export const SpinProvider: React.FC<SpinProviderProps> = ({ children }) => {
    const [mustSpin, setMustSpin] = useState(false);
    const [hasStartedSpinning, setHasStartedSpinning] = useState(false);
    const [hasFinishedSpinning, setHasFinishedSpinning] = useState(false);

    return (
        <SpinContext.Provider value={{ mustSpin, setMustSpin, hasStartedSpinning, setHasStartedSpinning, hasFinishedSpinning, setHasFinishedSpinning }}>
            {children}
        </SpinContext.Provider>
    );
};

export const useSpin = () => useContext(SpinContext);
