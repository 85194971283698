import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SpinProvider } from './context/SpinContext';
import AppNavbar from './components/Navbar';
import WheelComponent from './components/WheelComponent';

// Create a QueryClient instance
const queryClient = new QueryClient();

const App: React.FC = () => {
    const spinRef = new URLSearchParams(window.location.search).get('sr') || '';

    return (
        <QueryClientProvider client={queryClient}>
            <SpinProvider>
                <AppNavbar />
                <div className="container" style={{ paddingTop: '80px' }}> {/* Adjusted for navbar height */}
                    <WheelComponent spinRef={spinRef} />
                </div>
            </SpinProvider>
        </QueryClientProvider>
    );
};

export default App;
