import { useQuery } from '@tanstack/react-query';

const fetchWheelData = async (spinRef: string) => {
    const response = await fetch(`https://seahorse-app-46qz2.ondigitalocean.app/s/${spinRef}`);
    if (!response.ok) throw new Error('Network response was not ok');
    return response.json();
};

export const useFetchWheelData = (spinRef: string) => {
    return useQuery({
        queryKey: ['wheelData', spinRef],
        queryFn: () => fetchWheelData(spinRef),
        refetchOnWindowFocus: false,
    });
};
